<template>
    <div>
        <div class="chatbot-open" v-show="open && valuador_hide">
            <div :class="`chatbot-header ${chatbot_home ? 'chatbot-header-home' : ''}`">
                <i v-if="!chatbot_home" class="fa-regular fa-arrow-circle-left fa-2xl" style="color: #fff;"
                   @click="showInstanceContent(instance_values.back_instance.value)"></i>
                <div class="avatar-container">
                    <img :src="avatar" alt="avatar" class="mx-auto"/>
                </div>
                <i class="fa-regular fa-circle-xmark fa-2xl" style="color: #ffffff;"
                   @click="openCloseChat()"></i>
            </div>
            <div :class="`chatbot-container ${!chatbot_footer ? 'chatbot-container-no-footer' : ''}`">
                <div v-if="instance_values.title" class="chatbot-titles chatbot-title"
                     v-html="instance_values.title.value"></div>
                <div v-if="instance_values.subtitle_1" class="chatbot-titles chatbot-subtitle-1"
                     v-html="instance_values.subtitle_1.value"></div>
                <div v-if="instance_values.subtitle_2" class="chatbot-titles chatbot-subtitle-2"
                     v-html="instance_values.subtitle_2.value"></div>
                <div class="chatbot-buttons">
                    <div v-for="(item, index) in instance_values.items.value">
                        <template
                            v-if="instance_values.items_type.value === 'button' && !(item.cta_url.startsWith('tel') && isDesktop)">
                            <template v-if="item.title !== null || item.title !== ''">
                                <div v-html="item.title"></div>
                            </template>
                            <a :href="item.cta_url" class="btn btn-primary btn-blue w-100 mx-auto my-2 chatbot-button"
                               v-html="item.cta_text"></a>
                        </template>
                        <div v-if="instance_values.items_type.value === 'p'">
                            <div v-if="index !== 0" class="chatbot-list-divider"></div>
                            <div class="chatbot-list-item">
                                <div><p class="chatbot-list-number">{{ index + 1 }}</p></div>
                                <div v-html="item.title"></div>
                            </div>
                            <div v-if="item.cta_url !== null" class="chatbot-list-cta">
                                <a :href="item.cta_url" class="btn btn-primary btn-orange w-75 mx-auto my-0"
                                   v-html="item.cta_text"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="chatbot_footer" v-show="!hide_chatbot_footer"
                 :class="['chatbot-footer', chatbotFooterClass(instance_values.cta_footer.value.footer_type)]"
                 @click="chatbotFooterCTA(instance_values.cta_footer.value.footer_type, instance_values.cta_footer.value.next_instance)">
                <i v-if="instance_values.cta_footer.value.footer_type === 'info'"
                   class="fa-regular fa-info-circle fa-xl" style="color: var(--prestaprenda-blue);"></i>
                <span v-if="instance_values.cta_footer.value.footer_type === 'valuador'">
                    {{ (active_conversation) ? "Regresar a conversación" : instance_values.cta_footer.value.cta_text }}
                </span>
                <span v-else>
                    {{ instance_values.cta_footer.value.cta_text }}
                </span>
            </div>
        </div>
        <div class="chatbot-closed" v-show="!open && valuador_hide" @click="openCloseChat()">
            <div class="icon-container">
                <div>
                    <div class="icon-content">
                        <span>¡Hola!</span>
                    </div>
                    <div class="caret"></div>
                </div>
                <img :src="avatar" alt="avatar" width="36.14475px" height="34.7219664px"/>
            </div>
            <div class="text-center">
                <span style="font-size: 15px; font-weight: bold;">Yo te ayudo</span>
            </div>
        </div>
        <div v-if="show" v-show="!valuador_hide">
            <Chatbot :avatar="avatar" :budget="budget" :openChatbot="show" :productList="productList"
                     @chatbot-valuador-close="chatbotValuadorHide()"></Chatbot>
        </div>
    </div>
</template>

<script>
import Valuation from "@/components/mixins/valuation.js"
import Chatbot from "../appraiser/Chatbot.vue"
import {mapState} from "vuex"

export default {
    components: {Chatbot},
    props: {chatbot_content: Array},
    mixins: [Valuation],
    data() {
        return {
            open: false,
            avatar: this.chatbot_content.find(instance => instance.slug === 'chatbot-home').values.chatbot_avatar.value.public_path,
            instance_values: null,
            chatbot_home: false,
            chatbot_footer: false,
            active_conversation: false,
            isDesktop: window.innerWidth >= 1024,
            hide_chatbot_footer: false,
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateIsDesktop)
    },
    created() {
        let chatbotInstanceSlug = 'chatbot-home'
        let pathname = window.location.pathname
        let partialSlug = pathname.replaceAll('/', '-')

        if (!(partialSlug === null || partialSlug === '-')) {
            chatbotInstanceSlug = `chatbot${partialSlug}`
        }

        let chatbot_open = localStorage.getItem('chatbot_open')
        this.open = (JSON.parse(chatbot_open) === true)

        this.showInstanceContent(chatbotInstanceSlug)

        let ssValuadorActive = sessionStorage.getItem('valuador_active')

        if (ssValuadorActive === 'true') {
            sessionStorage.removeItem('ws_url')
            sessionStorage.removeItem('conversation_id')
            sessionStorage.setItem('valuador_active', 'false')
        }

        let ssWSURL = sessionStorage.getItem('ws_url')
        let ssConversationId = sessionStorage.getItem('conversation_id')

        if (window.location.pathname === '/valuador/empeno/cuanto-necesitas') {
            ssWSURL = null
            ssConversationId = null
        }

        if (ssWSURL && ssConversationId) {
            this.$store.commit('setShow', true)
            this.$store.commit('setValuadorHide', !this.open)
            this.active_conversation = true
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateIsDesktop)
    },
    methods: {
        openCloseChat() {
            this.open = !this.open
            localStorage.setItem('chatbot_open', (this.open).toString())
        },
        showInstanceContent(instance_slug) {
            if (instance_slug === null || instance_slug === '')
                return

            this.chatbot_home = (instance_slug === 'chatbot-home')

            let instance = this.chatbot_content.find(instance => instance.slug === instance_slug)

            if (instance === null || instance === undefined) {
                instance = this.chatbot_content.find(instance => instance.slug === 'chatbot-home')
                this.chatbot_home = true
            }

            this.instance_values = instance.values
            this.chatbot_footer = instance.values.cta_footer.value.cta_url || instance.values.cta_footer.value.next_instance
            this.hide_chatbot_footer = (instance.values.cta_footer.value.footer_type === 'valuador' && !this.active_conversation)
        },
        chatbotFooterClass(footerType) {
            switch (footerType) {
                case 'info':
                    return 'chatbot-footer-info'
                case 'valuador':
                    return 'chatbot-footer-valuador'
                default:
                    return ''
            }
        },
        chatbotFooterCTA(footerType, nextInstance) {
            switch (footerType) {
                case 'info':
                    this.showInstanceContent(nextInstance)
                    break
                case 'valuador':
                    this.$store.commit('setShow', true)
                    this.$store.commit('setValuadorHide', false)
                    break
                default:
                    return ''
            }
        },
        chatbotValuadorHide() {
            this.$store.commit('setValuadorHide', true)
            this.active_conversation = true
            this.open = true
            this.showInstanceContent('chatbot-mas-informacion')
        },
        updateIsDesktop() {
            this.isDesktop = (window.innerWidth >= 1024)
        },
    },
    computed: {
        ...mapState(['show', 'productList', 'budget', "valuador_hide"]),
    },
}
</script>

<style lang="scss" scoped>

.chatbot-open {
    position: fixed;
    width: 24rem;
    bottom: 0;
    right: 5rem;
    z-index: 1000;
    background: var(--prestaprenda-orange);
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    .chatbot-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        height: 60px;

        i {
            cursor: pointer;
        }
    }

    .chatbot-header-home {
        justify-content: flex-end !important;
    }

    .chatbot-container {
        background: white;
        height: 355px;
        width: 100%;
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
    }

    .chatbot-container-no-footer {
        height: 415px;
    }

    ::v-deep .chatbot-titles {
        p {
            font-size: 18px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
        }
    }

    ::v-deep .chatbot-title {
        p {
            color: #000;
        }
    }

    ::v-deep .chatbot-subtitle-1 {
        p {
            color: var(--prestaprenda-orange);
        }
    }

    ::v-deep .chatbot-subtitle-2 {
        p {
            color: var(--prestaprenda-blue);
        }
    }

    .chatbot-buttons {
        width: 90%;
    }

    .chatbot-list-number {
        color: #fff;
        background-color: var(--prestaprenda-blue);
        border-radius: 50%;
        width: 26px;
        height: 26px;
        margin: auto;
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chatbot-list-item {
        display: flex;
        column-gap: 1rem;
    }

    .chatbot-list-title {
        color: #757575;
    }

    .chatbot-list-cta {
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }

    .chatbot-list-divider {
        height: 1px;
        background-color: var(--background-gray);
        margin-bottom: 10px;
    }

    .chatbot-footer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.5rem;
        padding: 1rem;
        height: 60px;
        cursor: pointer;

        span {
            font-size: 16px;
            font-weight: 900;
            letter-spacing: 0;
            text-align: center;
        }
    }

    .chatbot-footer-info {
        color: var(--prestaprenda-blue);
        background-color: #E7EAF0;
    }

    .chatbot-footer-valuador {
        color: #fff;
        background-color: #33A522;
        height: 45px;
    }

    .avatar-container {
        position: absolute;
        left: 50%;
        top: -50%;
        transform: translateX(-50%);
        box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
}

.chatbot-closed {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    cursor: pointer;
    position: fixed;
    z-index: 1;
    width: 145px;
    height: 50px;
    bottom: 15%;
    right: 0;
    border-radius: 50px 0 0 50px;
    background: var(--prestaprenda-blue);

    .avatar-container {
        width: 50px;
        height: 50px;
    }
}

.icon-content {
    display: block;
    position: absolute;
    top: -15px;
    left: 115px;
    transform: translateX(-70%);
    background: var(--prestaprenda-orange);
    padding: 2px 20px;
    width: max-content;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 2rem;
    z-index: 12100;
}

.caret {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    width: 0px;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
}

.avatar-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--prestaprenda-blue);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 70%;
        height: 70%;
    }
}

@media (max-width: 600px) {
    .chatbot-open {
        width: 100%;
        right: 0;
    }
}
</style>